import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Autoplay } from "swiper/modules";
import { SwiperNavButtons } from "../../components/SwiperNavButton";
import { Link, useNavigate } from "react-router-dom";
import { SwiperNavYellow } from "../../components/SwiperNavYellow";
import { fetchData } from "../../Helper/ApiCall";
import {
    END_POINT,
    BASE_URL_IMAGE,
    METHODS,
    NumberSeprator,
    numberToEnglish,
    TabBarLabels,
    BASE_URL,
} from "../../Helper/helper";

// import "../../../src/weddingstylee.css";

import EditorRead from "../../components/EditorRead";
import Testimonial from "../../components/Testimonial";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
import { useSelector, useDispatch } from "react-redux";
import { homedataUpdate } from "../../redux/reducers/homeReducer";
import ActivityLoader from "../../components/ActivityLoader";
import Testimonials from "./Testimonials";
import PartnersCarousel2 from "../../components/PartnersCrousal2";

export const NewWeddingScreen = () => {
    const dispatch = useDispatch();

    const { HomeBanner, SectionSecond, RecomendedBlog, SectionForth, loading } =
        useSelector((store) => store.homeReducer);

    const navigate = useNavigate();
    const [data, setdata] = useState({
        WeddingDetail: [],
        Wedding: [],
        WeddingBanner: [],
        WeddingReal: [],
        WeddingInspiration: [],
    });
    const [TestimonialData, setTestimonialData] = useState([]);
    const [BannerData, setBannerDetail] = useState([]);
    const [VenuData, setVenu] = useState([]);
    const [RealWeddingData, setRealWedding] = useState([]);
    const [InspirationData, setInspiration] = useState([]);
    const [HighlightsData, setHighlights] = useState([]);

    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            console.log('Video Element:', videoRef.current);
        }
    }, []);

    useEffect(() => {
        handlerData();
        if (SectionForth == "") {
            homeHandler();
        }
        BannerHandler();
        VenuHandler();
        RealWeddingHandler();
        InspirationHandler();
        HighlightsHandler();
        // handler();
        // handlerInspiration();
    }, []);
    const handlerData = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingPageData);
            if (result.success == true) {
                setdata({
                    WeddingDetail: result.data.WeddingDetail,
                    // Wedding: result.data.Wedding,
                    // WeddingBanner: result.data.WeddingBanner,
                    // WeddingReal: result.data.WeddingReal,
                    // WeddingInspiration: result.data.WeddingInspiration,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    const homeHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetWebHomeAllData);
            console.log(result);
            if (result.success == true) {
                dispatch(homedataUpdate(result.data));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handler = async () => {
        try {
            const result = await fetchData(
                END_POINT.GetDetailByPageDataTestimonial + "Wedding"
            );
            if (result.success == true) {
                setTestimonialData(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const BannerHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingBannerByid + "66fe59dc12f1b2aa1cf0abac");

            if (result.success === true) {
                setBannerDetail(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const VenuHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingVenu);

            if (result.success == true) {
                setVenu(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const RealWeddingHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewRealWedding);

            if (result.success == true) {
                setRealWedding(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const InspirationHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingInspiration);

            if (result.success == true) {
                setInspiration(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const HighlightsHandler = async () => {
        try {
            const result = await fetchData(END_POINT.GetNewWeddingHighlights);

            if (result.success == true) {
                setHighlights(result.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add functionality to handle the email submission
        console.log(`Email submitted: ${email}`);
    };

    const [showMore, setShowMore] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);

    // To toggle between showing more or less text
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    // Detect if the user is on a desktop view (adjust based on your breakpoints)
    const handleResize = () => {
        setIsDesktop(window.innerWidth > 768); // Assuming 768px is your mobile breakpoint
    };

    useEffect(() => {
        handleResize(); // Set initial view
        window.addEventListener('resize', handleResize); // Listen to window resize

        return () => {
            window.removeEventListener('resize', handleResize); // Cleanup event listener
        };
    }, []);

    // Limit words for mobile view
    const getLimitedText = (text, wordLimit) => {
        if (!text) return ''; // Return empty string if text is undefined or null
        const words = text.split(' ');
        return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
    };


    const stripHtmlTags = (html) => {
        if (!html) return '';
        return html.replace(/<[^>]+>/g, '');
    };


    const options = {
        loop: false,
        margin: 10,
        nav: true,
        dots: true,
        // navText: ['<', '>'],
        responsive: {
            0: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 4,
            },
        },
    };

    return (
        <>

            <style>
                {`
                    body {
    font-family: "Playfair Display", serif;

}

a,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
    font-family: "Playfair Display", serif;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0px;
    padding: 0px;
}

p {
    font-family: "Playfair Display", serif;
    font-weight: 400;


}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Playfair Display", serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.4em;
    color: #000;
}

ul {
    list-style-type: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
}

h3 {
    font-size: 25px;
    line-height: 30px;
}

a {
    outline: 0;
    color: #222;
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -ms-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in;
    text-decoration: none;
}

a:hover {
    color: #0a1729;
    text-decoration: none;
}

.btn {
    outline: 0;
    -webkit-transition: all .25s ease-in;
    -moz-transition: all .25s ease-in;
    -ms-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    transition: all .25s ease-in;
    cursor: pointer;
}

button:focus {
    outline: none;
}

.container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
}

.main_section {
    width: 100%;
    margin: 0px;
    padding: 50px 0;
}


/******* Back-to-top ************/
.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 54px;
    height: 54px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 9999;
    font-size: 21px;
    padding: 11px 0;
    background-color: #fbc508;
    border-color: #fbc508;
}

.btn-dark:hover {
    color: #fff;
    background-color: #fbc508;
    border-color: #fbc508;
}

/******* Header ************/
.header {
    padding: 0px;
}

.banner {
    padding: 0px;
}

p.moretext {
    margin-top: -13px;
}

.about {
    /* background-image: url(../images/our-concept.jpg); */
    background-size: cover;
    background-position: center;
    padding: 80px 0;
    margin-top: -8px;
}

a.moreless-button {
    display: none;
}

.about-text {
    width: 80%;
    margin: 0 auto;
}

.about-text h2 {
    font-size: 48px;
    color: #FFCC00;
    font-style: italic;
    position: relative;
    margin-bottom: 20px;
}

.about-text h2::before {
    content: '';
    background: #fff;
    height: 1px;
    width: 120px;
    position: absolute;
    bottom: -8px;
    left: 44%;
}

.about-text p {
    font-size: 18px;
    color: #fff;
}

.weeding {}

.weeding-text {}

.weeding-text h2 {
    color: #0A3874;
}

.weeding-text h2::before {
    background: #FFCC00;
}

.weeding-text p {
    color: #333333;
}

.place-box-new {
    position: relative;
    vertical-align: top;
    position: relative;
    will-change: opacity;
    transition: opacity .4s ease-in-out;
    background: #000;
    margin: 0px 0 0;
    overflow: hidden;
    border-radius: 7px;
}

.cus-place-new-img {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    overflow: hidden;
}

.cus-place-new-img img {
    webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: auto;
    transition: all .2s ease-in-out;
    width: 100%;
    object-fit: cover;
    opacity: 0.7;
}

.place-box-new:hover .cus-place-new-img img {
    webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0.5;
}

.cus-place-text-new {
    position: absolute;
    left: 0;
    top: inherit;
    text-align: left;
    transform: translate(0, -50%);
    width: 100%;
    padding: 0 20px;
    bottom: 0;
}

.cus-place-text-new h3 {
    color: #fff;
    font-size: 21px;
}

.weeding-box {
    display: flex;
    flex-wrap: wrap;
}

.left-weeding-box {
    width: 50%;
    padding: 0 20px 0 0;
}

.left-weeding-box .place-box-new {
    height: 660px;
}


.right-weeding-box {
    width: 50%;
}

.right-bottom {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0 0;
}

.right-one {
    width: 50%;
    padding: 0 10px 0 0;
}

.right-two {
    width: 50%;
    padding: 0 0 0 10px;
}

.right-top .place-box-new {
    height: 360px;
}

.right-bottom .place-box-new {
    height: 283px;
}

.right-bottom .place-box-new-mice {
    height: 310px !important;
}

.real {
    position: relative;
    padding: 100px 0;
}

.real-img {
    position: absolute;
    left: 0px;
    top: -40px;
}

.real-box {
    display: flex;
    flex-wrap: wrap;
}

.real-text {
    width: 30%;
    padding: 0 30px 0 0;
}

.wp-real-box {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
}

.real-text h2 {
    font-size: 48px;
    color: #FFCC00;
    font-style: italic;
    position: relative;
    margin-bottom: 20px;
}

.real-text p {
    font-size: 18px;
}


.real-img1 {
    position: absolute;
    right: 0;
}

.main-real-box {
    width: 50%;
    padding: 0 9px 18px;
}

.main-real-box .place-box-new {
    height: 280px;
}

.venues {
    padding: 60px 0 0;
}

.wp-according .accordion {
    display: flex;
    height: 95vh;
    position: relative;
    overflow: hidden;
}

.wp-according .accordion .item {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex: 2;
    transition: flex 400ms;
    z-index: 7;
}

.wp-according .accordion .item:hover {
    flex: 4;
}

.bg-barcelona {
    background-image: url('https://bharatchoice.world/assets/images/Rectangle-1.jpg');
}

.bg-sydney {
    background-image: url('https://bharatchoice.world/assets/images/Rectangle-2.jpg');
}

.bg-venice {
    background-image: url('https://bharatchoice.world/assets/images/Rectangle-3.jpg');

}

/* .bg-singapore {
    background-image: url(../images/team-03.jpg);
  }
   */
/* .bg-san-francisco {
    background-image: url(https://images.unsplash.com/photo-1516675302207-722c37ce2f71?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2592&q=60);
  } */
.overlay {
    position: absolute;
    width: 100%;
    top: 89%;
    transition: 2s;
    -webkit-transition: 1s;
    background: #0000009c;
    transform: translate(0%, -89%);
    height: 100%;
    opacity: 0;
}

.wp-according .accordion .item:hover .overlay {
    top: 89%;
    opacity: 1;

}

.overlay-inner {
    padding: 10px;
    top: 43%;
    position: absolute;
    left: 17%;
}

.overlay-inner h3 {
    color: #fff;
    font-size: 24px;
    line-height: 26px;
}

.overlay-inner h3 a {
    color: #fff;
    font-size: 24px;
    line-height: 26px;
}


.overlay-inner p {
    color: #fff;
    margin: 0 0 5px;
}

.overlay-inner p a {
    color: #fff;
    margin: 0 0 8px;
    display: inline-block;
}

.overlay-inner ul li {
    display: inline-block;
}

.overlay-inner ul li a {
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    padding: 5px;
    border: 1px solid #fff;
    border-radius: 100px;
    width: 44px;
    height: 44px;
    text-align: center;
    color: #fff;
}

.testimonial {
    background: rgb(6, 31, 64);
    background: linear-gradient(180deg, rgba(6, 31, 64, 1) 0%, rgba(10, 56, 116, 1) 100%);
}

.textimonial-box {
    display: flex;
    position: relative;
    margin: 60px 0;
}

.textimonial-box::before {
    content: '';
    border: 5px solid #B3C1D4;
    height: 134%;
    width: 80%;
    position: absolute;
    right: 0;
    z-index: -1;
    top: -42px;
}


.left-textimonial {
    width: 40%;
}

.right-textimonial {
    width: 60%;
    padding: 0 25px;
}

.right-textimonial p {
    font-size: 18px;
    color: #fff;
}

.right-textimonial span {
    font-size: 16px;
    color: #FFCC00;
}

.cta {
    /* background-image: url(../images/CTA.png); */
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    padding: 110px 0;
}

.cta-text {}

.cta-text h2 {
    font-size: 40px;
    color: #FFCC00;
    font-style: italic;
}

.cta-btn {
    background: #FFCC00;
    padding: 15px 30px;
    border-radius: 50px;
    color: #0A3874;
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
    margin: 15px 0 0 0;
}

.cta-btn:hover {
    background: #fff;
}

.cta-btn1 {
    border: 1px solid #FFCC00;
    padding: 15px 30px;
    border-radius: 50px;
    color: #FFCC00;
    font-size: 17px;
    font-weight: 600;
    display: inline-block;
    margin: 15px 0 0 15px;
}

.cta-btn1:hover {
    border: 1px solid #FFCC00;
    background: #FFCC00;
    color: #0A3874;
}


.highlight {}

.highlight .about-text {
    width: 100%;
    margin: 0px;
}

.highlight .about-text p {
    color: #383838;
}

.highlight .about-text h2::before {
    background: #0A3874;
    bottom: -8px;
    left: 0;
}

.highlight .destination-text h2::before {
    background: none !important;
    bottom: -8px;
    left: 0;
}




.main-highlight-box {
    display: flex;
    flex-wrap: wrap;
}

.highlight-box {
    width: 33%;
    padding: 20px 10px 0;
}

.cus-newsletter {
    width: 40%;
    margin: 30px auto 0;
    position: relative;
    background: #fff;
    padding: 10px;
    border-radius: 12px;
}

.cus-newsletter .form-control {
    border: none;
}

.cus-newsletter .btn-primary {
    border-color: #FFCC00;
    background: #FFCC00;
    padding: 10px 20px;
    border-radius: 10px;
    color: #0A3874;
}

.left-textimonial img {
    height: 250px;
    border-radius: 6px;
    object-fit: cover;
}

.highlight-box img {
    border-radius: 6px;
}

video {
    width: 100%;
}





/* media screen and (max-width: 1170px)
---------------------------------------------------------------------- */
@media screen and (max-width:1170px) {
    .container {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        padding: 0px 15px;
    }



}

@media screen and (max-width: 980px) {}

/* media screen and (max-width: 728px)
---------------------------------------------------------------------- */
@media screen and (max-width:728px) {

    .moretext {
        display: none;
    }

    p.moretext {
        margin-top: 0px;
    }

    a.moreless-button {
        display: block;
        color: #FFCC00;
        font-size: 18px;
    }

    .about-text h2 {
        font-size: 30px;
    }

    .about-text h2::before {
        left: 24%;
    }

    .about-text {
        width: 100%;
        margin: 0 auto;
    }

    .about-text p {
        font-size: 16px;

        margin-bottom: 0;
    }

    .weeding-text h2::before {
        left: 34%;
    }

    .left-weeding-box {
        width: 100%;
        padding: 0;
        margin-bottom: 6px;
    }

    .right-weeding-box {
        width: 100%;
    }

    .right-one {
        width: 100%;
        padding: 0;
    }

    .right-two {
        width: 100%;
        padding: 6px 0 0 0;
    }

    .right-top .place-box-new {
        height: 238px;
    }

    .right-bottom {

        padding: 6px 0 0 0;
    }

    .cus-place-text-new h3 {
        font-size: 17px;
    }

    .real-text {
        width: 100%;
        padding: 0;
        text-align: center;
    }

    .main-real-box {
        width: 50%;
        padding: 0 7px 15px;
    }

    .real-text h2 {
        font-size: 30px;
        margin-bottom: 0;
    }

    br {
        display: none;
    }

    .real-img {
        width: 27%;
    }

    .real {
        position: relative;
        padding: 40px 0;
    }

    .main-real-box .place-box-new {
        height: 190px;
    }

    .main-real-box .cus-place-new-img {
        height: 220px;
    }

    .main-real-box .cus-place-new-img img {
        height: 210px;
    }

    .main-real-box .cus-place-text-new {
        transform: translate(0, -19%);
    }

    .main-real-box .cus-place-text-new h3 {
        font-size: 15px;
        line-height: 18px;
    }

    .real-img1 {
        width: 20%;
    }

    .wp-according .accordion {
        display: flex;
        height: 166vh;
        flex-wrap: wrap;
    }

    .wp-according .accordion .item {
        width: 100%;
        flex: inherit;
        margin-bottom: 5px;
    }

    .wp-according .accordion .item:hover {
        flex: inherit;
    }

    .overlay {
        position: relative;
        width: 100%;
        top: 88%;
        opacity: 1;
        background: #0000003d;
    }

    .overlay-inner {
        padding: 10px;
        top: 83%;
        position: absolute;
        left: 2%;
    }

    .wp-according .accordion .item:hover .overlay {
        top: 90%;
    }

    .testimonial .about-text h2::before {
        left: 34%;
    }

    .textimonial-box {
        margin: 60px 0;
        flex-wrap: wrap;
        padding: 0 12px;
    }

    .left-textimonial {
        width: 100%;
    }

    .right-textimonial {
        width: 100%;
        padding: 14px 5px;
    }

    .textimonial-box::before {
        content: '';
        border: 3px solid #B3C1D4;
        height: 107%;
        width: 100%;
        position: absolute;
        right: 0;
        z-index: -1;
        top: -22px;
    }

    .right-textimonial p {
        font-size: 15px;
        color: #fff;
    }

    .owl-theme .owl-nav {
        margin-top: 10px;
        display: none;
    }

    .cta-text h2 {
        font-size: 27px;
    }

    .highlight-box {
        width: 100%;
        padding: 20px 0px 0;
    }

    .cus-newsletter {
        width: 100%;

    }

    .highlight-box img {
        width: 100%;
    }

    .left-textimonial img {
        height: 100px;
        width: 100px !important;
        object-fit: cover;
    }

    .left-weeding-box .place-box-new {
        height: 410px;
    }

    .wp-real-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

}


/* media screen and (max-width: 640px)
---------------------------------------------------------------------- */
@media screen and (max-width:640px) {}


/* media screen and (max-width: 550px)
---------------------------------------------------------------------- */
@media screen and (max-width:550px) {}



/* media screen and (max-width: 480px)
---------------------------------------------------------------------- */
@media screen and (max-width:500px) {}



/* media screen and (max-width: 414px)
---------------------------------------------------------------------- */
@media screen and (max-width:375px) {}



/* Desktop view */
.desktop-view {
    display: block;
}

.mobile-view {
    display: none;
}

/* Mobile view */
@media (max-width: 768px) {
    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }

    /* Style adjustments for the carousel */
    .owl-carousel .item {
        text-align: center;
        padding: 10px;
    }

    .owl-nav {
        position: absolute;
        top: 50%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
    }

    .owl-prev,
    .owl-next {
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 10px;
        border-radius: 50%;
    }
}

.moretext.hide {
    display: none;
}

.moretext.show {
    display: block;
}

.owl-carousel .item img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.owl-carousel .item video {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.overlay-inner {
    left: 37%;
    padding: 10px;
    position: absolute;
    top: 43%;
}

.overlay {
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0;
    position: relative;
    right: 0;
    top: 0;
    transition: .5s ease;
    width: 100%;
    text-align: center;
}
            
                    
                `}
            </style>

            {/* Video Banner Section */}
            <div className="main_section banner">

                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    loop
                    playsInline
                    preload="metadata"
                    style={{ width: '100%', height: 'auto' }} // Or use fixed dimensions if needed
                >
                    <source src={BASE_URL_IMAGE + BannerData.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>


            </div>


            {/* About Section */}
            <div className="main_section about"
                style={{ backgroundImage: "url('/assets/images/our-concept.jpg')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text">
                                <h2> {BannerData.title} </h2>
                                <div className="article">
                                    {/* Full description for desktop, limited words for mobile */}
                                    <p>
                                        {isDesktop || showMore
                                            ? stripHtmlTags(BannerData.short_desc) // Display the full text without HTML tags
                                            : getLimitedText(BannerData.short_desc, 25) /* Show first 25 words on mobile */}
                                    </p>

                                    {/* Only show 'See more/See less' button on mobile view */}
                                    {!isDesktop && (
                                        <a className="moreless-button" onClick={toggleShowMore}>
                                            {showMore ? 'See less' : 'See more'}
                                        </a>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div >


            <div className="main_section weeding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text weeding-text">
                                <h2>Wedding inspiration!</h2>
                                <p>
                                    A series showcasing inspirational and stylish weddings held in
                                    the finest venues of Thailand.
                                </p>
                            </div>
                        </div>


                        {/* Desktop View */}
                        <div className="col-md-12 p-0 mt-4 desktop-view">
                            <div className="weeding-box">
                                <div className="left-weeding-box">
                                    {InspirationData[0] && (
                                        <div className="place-box-new">
                                            <div className="cus-place-new-img">
                                                <img
                                                    src={BASE_URL_IMAGE + InspirationData[0].image}
                                                    className="img-fluid"
                                                    alt={InspirationData[0].title || "Inspiration Image"}
                                                />
                                            </div>
                                            <div className="cus-place-text-new">
                                                <h3>{InspirationData[0].title}</h3>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="right-weeding-box">
                                    <div className="right-top">
                                        {InspirationData[1] && (
                                            <div className="place-box-new">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src={BASE_URL_IMAGE + InspirationData[1].image}
                                                        className="img-fluid"
                                                        alt={InspirationData[1].title || "Inspiration Image"}
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>{InspirationData[1].title}</h3>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="right-bottom">
                                        <div className="right-one">
                                            {InspirationData[2] && (
                                                <div className="place-box-new">
                                                    <div className="cus-place-new-img">
                                                        <img
                                                            src={BASE_URL_IMAGE + InspirationData[2].image}
                                                            className="img-fluid"
                                                            alt={InspirationData[2].title || "Inspiration Image"}
                                                        />
                                                    </div>
                                                    <div className="cus-place-text-new">
                                                        <h3>{InspirationData[2].title}</h3>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="right-two">
                                            {InspirationData[3] && (
                                                <div className="place-box-new">
                                                    <div className="cus-place-new-img">
                                                        <img
                                                            src={BASE_URL_IMAGE + InspirationData[3].image}
                                                            className="img-fluid"
                                                            alt={InspirationData[3].title || "Inspiration Image"}
                                                        />
                                                    </div>
                                                    <div className="cus-place-text-new">
                                                        <h3>{InspirationData[3].title}</h3>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Mobile View - Carousel */}
                        <div className="col-md-12 p-0 mt-4 mobile-view">
                            <OwlCarousel options={options}>
                                {InspirationData.map((inspiration, index) => (
                                    <div key={index} className="item">
                                        <img
                                            src={BASE_URL_IMAGE + inspiration.image}
                                            className="img-fluid"
                                            alt={inspiration.title || "Inspiration Image"}
                                        />
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>


                    </div>
                </div>
            </div>

            <div className="main_section real desktop-view">
                <div className="real-img">
                    <img src="/assets/images/icon2.png" className="img-fluid" alt="Icon 2" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="real-box">
                                <div className="real-text">
                                    <h2>
                                        The Real <br />
                                        Wedding
                                    </h2>
                                    <p>
                                        These Real couples said their "I do" in a glamorous way.
                                        Let's walk you through their fairytale wedding.
                                    </p>
                                </div>

                                <div className="wp-real-box">
                                    {RealWeddingData.map((wedding, index) => (
                                        <div key={index} className="main-real-box">
                                            <div className="place-box-new">
                                                <div className="cus-place-new-img">
                                                    <img
                                                        src={BASE_URL_IMAGE + wedding.image}
                                                        className="img-fluid"
                                                        alt={wedding.title}
                                                    />
                                                </div>
                                                <div className="cus-place-text-new">
                                                    <h3>
                                                        {wedding.link ? (
                                                            <a href={wedding.link} target="_blank" rel="noopener noreferrer">
                                                                {wedding.title}
                                                            </a>
                                                        ) : (
                                                            wedding.title
                                                        )}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>




                            </div>
                        </div>
                    </div>
                </div>

                <div className="real-img1">
                    <img src="/assets/images/icon1.png" className="img-fluid" alt="Icon 1" />
                </div>
            </div >


            <div className="main_section real mobile-view">
                <div className="real-img">
                    <img src="/assets/images/icon2.png" className="img-fluid" alt="Icon 2" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="real-box">
                                <div className="real-text">
                                    <h2>
                                        The Real <br />
                                        Wedding
                                    </h2>
                                    <p>
                                        These Real couples said their "I do" in a glamorous way.
                                        Let's walk you through their fairytale wedding.
                                    </p>
                                </div>



                                <div className="wp-real-box mobile-view">
                                    {RealWeddingData.length > 0 && (
                                        <OwlCarousel options={options}>
                                            {RealWeddingData.map((wedding, index) => (
                                                wedding.image ? (
                                                    <div key={index} className="item">
                                                        <img
                                                            src={BASE_URL_IMAGE + wedding.image}
                                                            className="img-fluid"
                                                            alt={wedding.title || "Wedding Image"}
                                                        />
                                                    </div>
                                                ) : null // Ignore entries without an image
                                            ))}
                                        </OwlCarousel>
                                    )}


                                </div>


                            </div>
                        </div>
                    </div>
                </div>

                <div className="real-img1">
                    <img src="/assets/images/icon1.png" className="img-fluid" alt="Icon 1" />
                </div>
            </div >


            {/* Wedding Venues Section */}
            < div className="main_section weeding venues" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="about-text weeding-text">
                                <h2>Wedding Venues</h2>
                                <p>
                                    These venues are brimming with elegance, luxury, and natural beauty, <br />
                                    making them a perfect choice for your dream destination wedding
                                </p>
                            </div>
                        </div>

                        <div className="col-md-12 p-0 wp-according mt-4">
                            <div className="accordion">
                                {/* <div className="item bg-barcelona"
                                // style={{ backgroundImage: "url('/assets/images/Rectangle-1.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="item bg-sydney"
                                // style={{ backgroundImage: "url('/assets/images/Rectangle-2.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="item bg-venice"
                                // style={{ backgroundImage: "url('/assets/images/Rectangle-3.jpg')" }}
                                >
                                    <div className="overlay">
                                        <div className="overlay-inner">
                                            <h3>
                                                <a href="#">Name of the hotel</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div> */}

                                {VenuData.map((venue, index) => (
                                    <div
                                        key={index}
                                        className="item"
                                        style={{
                                            backgroundImage: `url(${BASE_URL_IMAGE + venue.image})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                        }}
                                    >
                                        <div className="overlay">
                                            <div className="overlay-inner">
                                                <h3>
                                                    {venue.link ? (
                                                        <a href={venue.link} target="_blank" rel="noopener noreferrer">
                                                            {venue.title}
                                                        </a>
                                                    ) : (
                                                        venue.title
                                                    )}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>


                        {/* <div className="col-md-12 p-0 wp-according mt-4 mobile-view">
                            <OwlCarousel options={options}>

                                <div className="item">
                                    <img src="/assets/images/Rectangle-1.jpg" className="img-fluid" alt="Must-do Activities" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Rectangle-2.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                                <div className="item">
                                    <img src="/assets/images/Rectangle-3.jpg" className="img-fluid" alt="Best Fine Dining 2" />
                                </div>
                            </OwlCarousel>
                        </div> */}
                    </div>
                </div>
            </div >

            <Testimonials />


            {/* Call to Action Section */}
            <div className="main_section cta"
                style={{ backgroundImage: "url('/assets/images/CTA.png')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="cta-text">
                                <h2>
                                    Let our wedding planners help you bring <br />
                                    the wedding of your dreams to life
                                </h2>
                                <a href="#" className="cta-btn">Our service offerings</a>
                                <a href="#" className="cta-btn1">Online appointment request</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Highlight Section */}
            {/* <div className="main_section highlight desktop-view">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text">
                                <h2>Wedding Highlights!</h2>
                                <p>A series showcasing inspirational and stylish weddings held in the finest venues of Thailand.</p>
                            </div>
                            <div className="main-highlight-box">
                                <div className="highlight-box">
                                    <img src="/assets/images/Preferred-Hotels.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                                <div className="highlight-box">
                                    <img src="/assets/images/Must-do-activities.jpg" className="img-fluid" alt="Must do activities" />
                                </div>
                                <div className="highlight-box">
                                    <img src="/assets/images/Preferred-Hotels.jpg" className="img-fluid" alt="Preferred Hotels" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="main_section highlight">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about-text">
                                <h2>Wedding Highlights!</h2>
                                <p>A series showcasing inspirational and stylish weddings held in the finest venues of Thailand.</p>
                            </div>
                            <br ></br>
                            <OwlCarousel options={options}>
                                {HighlightsData.map((highlight, index) => (
                                    <div className="item" key={index}>
                                        <video autoPlay muted loop playsInline preload="metadata">
                                            <source src={BASE_URL_IMAGE + highlight.video} type="video/mp4" />
                                        </video>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>


            <div className="main_section cta newsletter"
                style={{ backgroundImage: "url('/assets/images/CTA.png')" }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="cta-text">
                                <h2>Sign up for our amazing deals & Newsletter</h2>
                                <div className="cus-newsletter">
                                    <form className="row g-3" onSubmit={handleSubmit}>
                                        <div className="col-md-8 col-7">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="inputEmail4"
                                                placeholder="Enter email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-4 col-5">
                                            <button type="submit" className="btn btn-primary">Subscribe</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PartnersCarousel2 />


        </>
    );
};
